// package imports
import { Button } from '@mui/material';
import { DateTime } from 'luxon';
import { useSession } from 'next-auth/react';
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';

// local imports
import NotAuthorized from 'components/common/NotAuthorized';
import PleaseWait from 'components/common/PleaseWait';
import SortableTable from 'components/common/Table/sortableTable';
import { isBoysTownAdmin } from 'services/roleUtils';
import { useAddScrapeRequest, useVectorStorePageInfo } from './hooks/VectorPageInfoHooks';

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const action = (snackbarId) => (
  <>
    <button
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      Dismiss
    </button>
  </>
);

const AdminManageVectorStore = (props) => {
  const { data: session, status } = useSession();
  const { data, isLoading, isError, error: vectorStorePageInfoError } = useVectorStorePageInfo();

  const addScrapeRequest = useAddScrapeRequest();

  const columns = React.useMemo(
    () => [
      {
        header: () => <span>Page</span>,
        accessorFn: (row) => `${row.title} ${row.path}`,
        id: 'title',
        cell: ({ row }) => {
          return (
            <>
              <div>{row.original.title}</div>
              <div>{row.original.path}</div>
            </>
          );
        },
        filterFn: 'includesString', //note: normal non-fuzzy filter column - case insensitive
      },
      {
        enableColumnFilter: false,
        header: () => <span>Last Indexed</span>,
        accessorKey: 'lastIndexedTimestamp',
        cell: ({ row }) => {
          return (
            <Button
              title='Re-Index'
              onClick={() =>
                addScrapeRequest.mutate(
                  { path: row.original.path, action: 'index' },
                  {
                    onSuccess: () => {
                      enqueueSnackbar(`Scrape request added for ${row.original.path}`, { variant: 'success', autoHideDuration: 4000 });
                    },
                    onError: (e) => {
                      const key = enqueueSnackbar(`Error adding scrape request: ${e.message || defaultErrorMessage}`, {
                        variant: 'error',
                        persist: true,
                        SnackbarProps: {
                          onClick: () => {
                            closeSnackbar(key);
                          },
                        },
                      });
                    },
                  }
                )
              }
            >
              {row.original.lastIndexedTimestamp
                ? DateTime.fromMillis(row.original.lastIndexedTimestamp * 1000).toFormat('MM/dd/yyyy hh:mm:ss a')
                : 'Not Indexed'}
            </Button>
          );
        },
        sortingFn: (a, b) => {
          const aSort = a.original.lastIndexedTimestamp || 0;
          const bSort = b.original.lastIndexedTimestamp || 0;
          return aSort - bSort;
        },
        sortUndefined: 'last',
      },
      {
        enableColumnFilter: false,
        header: () => <span></span>,
        accessorKey: 'pageID',
        cell: ({ row }) => {
          if (row.original.lastIndexedTimestamp) {
            return (
              <Button
                title='Remove'
                onClick={() =>
                  addScrapeRequest.mutate(
                    { path: row.original.path, action: 'delete' },
                    {
                      onSuccess: () => {
                        enqueueSnackbar(`Remove request added for ${row.original.path}`, { variant: 'success', autoHideDuration: 4000 });
                      },
                      onError: (e) => {
                        const key = enqueueSnackbar(`Error adding remove request: ${e.message || defaultErrorMessage}`, {
                          variant: 'error',
                          persist: true,
                          SnackbarProps: {
                            onClick: () => {
                              closeSnackbar(key);
                            },
                          },
                        });
                      },
                    }
                  )
                }
              >
                Remove
              </Button>
            );
          }
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(`${vectorStorePageInfoError?.message || defaultErrorMessage}`, { variant: 'error', persist: true, action });
    }
  }, [isError, vectorStorePageInfoError]);

  if (!isBoysTownAdmin(session)) {
    return (
      <>
        <SnackbarProvider />
        <NotAuthorized />
      </>
    );
  }

  return (
    <React.Fragment>
      <PleaseWait isLoading={isLoading || addScrapeRequest.isPending} />
      <SnackbarProvider />

      {!isLoading && !isError && data && (
        <div>
          <SortableTable {...{ data, columns }} />
          {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        </div>
      )}
    </React.Fragment>
  );
};

export default AdminManageVectorStore;
